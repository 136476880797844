import React from "react";
import { useSelector } from "react-redux";

import { useIdleTimer } from "react-idle-timer";
import { getItem } from "../services/localStorageService";
import { apiEndPoints } from "../apis/index";
import api from "../apis/api";
import apiBaseUrl from "../hooks/getApiBaseUrl";
// import timeoutValue from "../hooks/getIdleTimeOut";

const AuthGuard = function (props) {
  const { user } = useSelector((state) => state);

  const pathname = window.location.pathname;

  const userData = getItem("auth-user");

  let authenticated = false;

  if (userData) {
    authenticated = !!userData.idToken;
  }

  const handleOnIdle = authenticated
    ? async () => {
        if (process.env.NODE_ENV === "development") {
          return;
        }

        const redirect_url = getItem("cognitoClientId")
          ? "/session/signin?user=gehc"
          : "/session/signin";

        if (pathname !== "/connectPage") {
          localStorage.clear();
          window.location.href = redirect_url;
          return;
        } else {
          if (!user.sessionKey) {
            localStorage.clear();
            window.location.href = redirect_url;
            return;
          }

          try {
            const status = await api({
              url: `${apiEndPoints.getScanConnectionStatus}${user.sessionKey}`,
              baseUrl: apiBaseUrl.baseURL,
              method: "GET",
            });
            if (status?.data?.data?.status === "Validation Pending") {
              localStorage.clear();
              window.location.href = redirect_url;
              return;
            }
          } catch (e) {
            console.error(e, "error in auth guard");
          }
        }
      }
    : void 0;

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
  });

  return <>{props.children}</>;
};

export default AuthGuard;
