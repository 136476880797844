import { apiEndPoints } from "../../apis";
import api from "../../apis/api";
import { setItem } from "../../services/localStorageService";
import {
  authUser,
  guestUser,
  authUserCredentials,
} from "../../constants/index";
import { showMessage } from "../../redux/actions/snackbar";
import { setUserData } from "../../redux/actions/UserActions";
import { aws4Interceptor } from "aws4-axios";

const namespace = "containers/session";

export const FETCH_DEMO_DATA = `${namespace}/FETCH_DEMO_DATA`;
export const SET_API_ERROR = `${namespace}/SET_API_ERROR`;
export const SET_REGISTRATION_DATA = `${namespace}/SET_REGISTRATION_DATA`;
export const USER_WITH_OTP = `${namespace}/USER_WITH_OTP`;
export const SET_SHOW_LOADER = `${namespace}/SET_SHOW_LOADER`;
export const SET_HIDE_LOADER = `${namespace}/SET_HIDE_LOADER`;

export const setApiError = (payload) => ({
  type: SET_API_ERROR,
  payload,
});

export const setUserInfo = (payload) => ({
  type: SET_REGISTRATION_DATA,
  payload,
});

export const setShowLoader = () => (dispatch) => {
  dispatch({
    type: SET_SHOW_LOADER,
  });
};

export const setHideLoader = () => (dispatch) => {
  dispatch({
    type: SET_HIDE_LOADER,
  });
};

export const makeRegistration =
  (payload, callback, setValue, setShowHavingDifficulty, setIsResendingOtp) =>
  async (dispatch) => {
    try {
      const response = await api.post(apiEndPoints.registrationAPI, payload);
      const { message } = response.data;
      if (response.status === 200) {
        const data = {
          email: payload.email,
        };
        callback && callback(true);
        setValue && setValue("not_getting_verification");
        setIsResendingOtp && setIsResendingOtp(false);
        dispatch(setUserInfo({ ...data }));
      }
      dispatch(showMessage({ show: true, message, type: "success" }));
      dispatch(setHideLoader());
    } catch (error) {
      const { message } = error.response.data;
      if (message === "Public email address is not accepted") {
        setShowHavingDifficulty && setShowHavingDifficulty(true);
      }
      dispatch(setApiError(error));
      dispatch(showMessage({ show: true, message, type: "error" }));
      dispatch(setHideLoader());
      return false;
    }
  };

export const confirmUser = (payload, props) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.userconfirmationAPI, payload);
    setShowLoader();
    const { message, data } = response.data;
    if (response.status === 200) {
      dispatch(showMessage({ show: true, message, type: "success" }));
      setItem(authUser, {
        accessToken: data.accessToken,
        idToken: data.idToken,
        emailId: payload.email,
      });
      setItem(authUserCredentials, {
        accessKeyId: data.credentials.accessKeyId,
        secretAccessKey: data.credentials.secretAccessKey,
        sessionToken: data.credentials.sessionToken,
      });
      console.log(data.credentials);
      console.log(data.credentials.sessionToken);

      const interceptor = aws4Interceptor(
        {
          region: "us-east-1",
          service: "execute-api",
        },
        {
          accessKeyId: data.credentials.accessKeyId,
          secretAccessKey: data.credentials.secretAccessKey,
          sessionToken: data.credentials.sessionToken,
        }
      );
      api.interceptors.request.use(interceptor);

      props.history.push("/incident-viewer");
      const userProfileInfo = await api.get(apiEndPoints.getUserProfile);
      setItem("userInfo", {
        name: userProfileInfo.data.data.name,
        email: payload.email,
      });
      dispatch(
        setUserData({
          name: userProfileInfo.data.data.name,
          email: payload.email,
        })
      );
    }
    dispatch(setHideLoader());
    return true;
  } catch (error) {
    dispatch(setApiError(error));
    const { message } = error.response.data;
    dispatch(showMessage({ show: true, message, type: "error" }));
    dispatch(setHideLoader());
    return false;
  }
};

export const doLogin =
  (email, callback, setIsResendingOtp) => async (dispatch) => {
    const payload = { email };
    try {
      const response = await api.post(apiEndPoints.loginAPI, payload);
      const { message } = response.data;
      if (response.status === 200) {
        dispatch(showMessage({ show: true, message, type: "success" }));
        dispatch(setUserInfo({ ...payload, isLoggedIn: true }));
        callback && callback(true);
        setIsResendingOtp && setIsResendingOtp(false);
      }
    } catch (error) {
      const { message } = (error && error.response && error.response.data) || {
        message: "Network error",
      };
      dispatch(showMessage({ show: true, message, type: "error" }));
      dispatch(setHideLoader());
    }
    dispatch(setHideLoader());
  };

export const verifyOtp =
  (
    email,
    password,
    props,
    scanId,
    selectedIntegration,
    authDetail,
    setAuthDetail
  ) =>
  async (dispatch) => {
    const payload = { email, verificationCode: password };
    try {
      const response = await api.post(apiEndPoints.verifyOtp, payload);
      const { message, data } = response.data;
      if (response.status === 200) {
        setItem(authUser, {
          accessToken: data.token.accessToken,
          idToken: data.token.idToken,
          refreshToken: data.token.refreshToken,
          inputUserInfo: data.inputUserInfo,
        });
        setItem(authUserCredentials, {
          accessKeyId: data.credentials.accessKeyId,
          secretAccessKey: data.credentials.secretAccessKey,
          sessionToken: data.credentials.sessionToken,
        });
        console.log(data.credentials);
        console.log(data.credentials.sessionToken);

        const interceptor = aws4Interceptor(
          {
            region: "us-east-1",
            service: "execute-api",
          },
          {
            accessKeyId: data.credentials.accessKeyId,
            secretAccessKey: data.credentials.secretAccessKey,
            sessionToken: data.credentials.sessionToken,
          }
        );
        api.interceptors.request.use(interceptor);

        const userProfileInfo = await api.get(apiEndPoints.getUserProfile);
        let result;
        try {
          result = await api({ url: apiEndPoints.getRealTimeScans });
        } catch (error) {
          console.log(error);
        }
        dispatch(showMessage({ show: true, message, type: "success" }));
        setItem("userInfo", { name: userProfileInfo.data.data.name, email });
        dispatch(
          setUserData({
            name: userProfileInfo.data.data.name,
            email: payload.email,
          })
        );

        let user_type = "";
        try {
          const userType = await api({ url: apiEndPoints.getUserType });
          const { customer_id, partner_id } = userType.data.data;
          if (partner_id && customer_id) {
            user_type = "PARTNER_AND_CUSTOMER";
          } else if (partner_id && !customer_id) {
            user_type = "PARTNER";
          } else if (customer_id) {
            user_type = "CUSTOMER";
          } else {
            user_type = "USER";
          }
          localStorage.setItem("userType", user_type);
        } catch (error) {
          console.log(error);
        }

        const redirect_url = new URLSearchParams(window.location.search).get(
          "redirect-url"
        );

        if (redirect_url) {
          return props.history.push("/" + redirect_url);
        }

        scanId
          ? props.history.push(`/summary-report?scanId=${scanId}`, {
              scanIdProps: scanId,
            })
          : selectedIntegration
          ? props.history.push("/connectPage", {
              integration: selectedIntegration,
            })
          : user_type === "PARTNER"
          ? props.history.push("/partner-view")
          : result?.data?.data?.UserMapping
          ? props.history.push("/summary-report")
          : props.history.push("/dashboard");
      }
      dispatch(setHideLoader());
    } catch (error) {
      const { message } = (error.response && error.response.data) || {};
      setAuthDetail && setAuthDetail({ ...authDetail, password: "" });
      dispatch(showMessage({ show: true, message, type: "error" }));
      dispatch(setHideLoader());
    }
  };

export const linkValidation =
  (invitationCode, setMessage) => async (dispatch) => {
    const payload = { scanShareID: invitationCode };
    try {
      const result = await api.post(apiEndPoints.linkValidation, payload);
      const { message } = result.data;
      if (result.status === 200) {
        setMessage(message);
        dispatch(showMessage({ show: true, message, type: "success" }));
        return true;
      }
      return false;
    } catch (error) {
      const { message } = error.response.data;
      dispatch(showMessage({ show: true, message, type: "error" }));
      return false;
    }
  };

export const verifyInvitee =
  (verificationCode, invitationCode, setIsLoading, setAuthDetail, history) =>
  async (dispatch) => {
    const payload = { scanShareID: invitationCode, otp: verificationCode };
    try {
      const result = await api.post(apiEndPoints.inviteeLogin, payload);
      const { message, data, sacnID } = result.data;
      if (result.status === 200) {
        setItem(guestUser, {
          accessToken: data.token,
          role: "guest",
          rights: "read-only",
          scanId: sacnID || "N/A",
        });
        dispatch(showMessage({ show: true, message, type: "success" }));
        setIsLoading(false);
        setAuthDetail({ password: "" });
        history.push("/inviteeLogin/report", {
          scanId: sacnID || "N/A",
          guestLogin: true,
        });
        return true;
      }
      setIsLoading(false);
      setAuthDetail({ password: "" });
      return false;
    } catch (error) {
      const { message } = (error.response && error.response.data) || {};
      dispatch(showMessage({ show: true, message, type: "error" }));
      setAuthDetail({ password: "" });
      setIsLoading(false);
      return false;
    }
  };

export const send =
  (payload, setValue, setIsOpen, setWithOtp, setShowHavingDifficulty) =>
  async (dispatch) => {
    try {
      const result = await api.post(apiEndPoints.reportOTPProblem, payload);
      const { message } = result.data;
      if (result.status === 200) {
        dispatch(showMessage({ show: true, message, type: "success" }));
        payload.subject === "not_getting_verification"
          ? setValue("not_getting")
          : setValue("signup_is_blocked");
        setIsOpen && setIsOpen(false);
        setWithOtp && setWithOtp(false);
        setShowHavingDifficulty && setShowHavingDifficulty(false);
      }
    } catch (error) {
      const { message } = (error.response && error.response.data) || {};
      dispatch(showMessage({ show: true, message, type: "success" }));
    }
  };

export const signOut = async (apiEndpoint, callback) => {
  try {
    callback && callback();
    // const result = await api.get(apiEndpoint);
    // if (result.status === 200) {
    //   callback && callback();
    // }
  } catch (error) {
    console.error(error);
  }
};
