import { EgretLoadable } from "egret/index.js";

//import PartnerViewHomePage from "./index.jsx";

const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false,
      showcontent: true,
    },
    leftSidebar: {
      show: false,
      mode: "close",
    },
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false,
    },
    navbar: { show: false },
  },
  secondarySidebar: { show: false },
  footer: { show: false },
};

const HomePage = EgretLoadable({
  loader: () => import("./index"),
});

const PartnerViewHomePageRoutes = [
  {
    path: "/partner-view",
    component: HomePage,
    settings,
  },
];

export default PartnerViewHomePageRoutes;
