import { EgretLoadable } from "egret";

const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false,
      showcontent: true,
    },
    navbar: { show: false },
    leftSidebar: {
      show: true,
      mode: "compact",
    },
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false,
    },
    navbar: { show: false },
  },
  secondarySidebar: { show: false },
  footer: { show: false },
};

const AdvancedSettings = EgretLoadable({
  loader: () => import("./AdvancedSettings.jsx"),
});

const workflowAdvancedSettingsRoutes = [
  {
    path: "/settings/workflow-managers/advance-settings",
    component: AdvancedSettings,
    settings,
  },
];

export default workflowAdvancedSettingsRoutes;
