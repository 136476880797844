import { CircularProgress } from "@material-ui/core";
import { apiEndPoints } from "app/apis";
import api from "app/apis/api";
import {
  CUSTOMER,
  PARTNER,
  PARTNER_AND_CUSTOMER,
  USER,
} from "app/constants/userType";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AuthCheck from "./AuthCheck";

const GetUserType = ({ children }) => {
  const hasUser = localStorage.getItem("userInfo");
  const [wait, setWait] = useState(hasUser ? true : false);

  useEffect(() => {
    const getUserType = async () => {
      setWait(true);
      try {
        const userType = await api({ url: apiEndPoints.getUserType });
        const { customer_id, partner_id } = userType.data.data;
        let user_type = "";
        if (partner_id && customer_id) {
          user_type = PARTNER_AND_CUSTOMER;
        } else if (partner_id && !customer_id) {
          user_type = PARTNER;
        } else if (customer_id) {
          user_type = CUSTOMER;
        } else {
          user_type = USER;
        }
        localStorage.setItem("userType", user_type);
      } catch (error) {
        console.log(error);
      }
      setWait(false);
    };

    if (hasUser) {
      getUserType();
    }
  }, []);

  return wait ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "grid",
        placeItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <AuthCheck>{children}</AuthCheck>
  );
};

export default GetUserType;
